import React from "react";
import Page from "../components/Page";

export default function PrivacyPolicy() {
    return (
        <>
            <Page title={"Privacy Policy"}>
                <div className="siteBackground">
                    <div className="wrapper">
                        <div className="greyPageCard articleText">
                            <h1 id="-privacy-policy-"><strong>Affiliate disclosure</strong></h1>
                            <br />
                            <p>Sometimes I will use affiliate links, all opinions and recommendations are my own and honest. I don’t get paid upfront by any of these for promoting their products.</p>
                            <h2 id="affiliate-links">Affiliate links</h2>
                            <p>Some of the outbound links (links leading to other websites) in my articles are affiliate links. They are tracked by merchants, such as Amazon, Adrecord, and Adtraction.</p>
                            <p>I get paid a percentage commission on any sales or signups that come as a result of my readers clicking on those links and buying. The commission I earn comes at no extra cost to you. You pay the same price no matter if it’s from my affiliate links or a non-affiliate link.</p>
                            <p>Every time there’s an affiliate link, I’ll put a disclosure in the article stating that the article contains affiliate links.</p>
                            <h2 id="amazon-disclosure">Amazon disclosure</h2>
                            <p>As per the Amazon Operating Agreement, I, www.pluggtips.se, am a participant in the Amazon Services LLC Associates Program. As an Amazon Associate, I earn from qualifying purchases. This is an affiliate advertising program designed to provide a means for this website to earn advertising fees and commissions by linking to Amazon.com and affiliated sites.</p>

                            <br />
                        </div>
                    </div>
                </div>
            </Page>
        </>
    );
}
